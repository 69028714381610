/* eslint-disable */
import Vue from 'vue'
import Router from 'vue-router'
import routes from 'vue-auto-routing'
import { createRouterLayout } from 'vue-router-layout'

Vue.use(Router)

const RouterLayout = createRouterLayout(layout => {
  return import('@/layouts/' + layout + '.vue')
})

// console.log(routes)
window.links = routes;

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: RouterLayout,
      children: routes
    }
  ]
})

import NavigationGuard from '@/utils/navigationGuard.js';
router.beforeEach((to, from, next) => NavigationGuard(to, from, next));

export default router;
