/* eslint-disable */
import Vue from 'vue';
import store from '@/store';

let isFirstNav = true;

export default async (to, from, next) => {
  if (isFirstNav) {
    try {
      await store.dispatch('auth/authenticate');
    } catch (e) {
      console.log('error on auth/authenticate ');
    }
    isFirstNav = false;
  }

  const requiresAuth = to.meta.auth !== false;
  const userAuthorized = store.state.auth?.accessToken;
  const userVerified = store.state.auth?.user?.isVerified;
  const redirectPath = from.fullPath ? from.fullPath : '/';

  console.table({
    requiresAuth,
    userAuthorized,
    redirectPath
  });

  //console.log(to);
  Vue.nextTick(() => {
    if (to?.meta?.title) {
      document.title = to.meta.title;
    }
  });

  if (requiresAuth) {
    if (userAuthorized) {
      if (to.name !== 'verify' && !userVerified) {
        // Redirect to verify, if user not verified
        next({
          name: 'verify',
          params: { nextUrl: to.fullPath }
        });
      } else {
        next();
      }
    } else {
      // Redirect to login, if requires auth
      next({
        name: 'login',
        params: { nextUrl: to.fullPath }
      });
    }
  } else if (to.name === 'login' && userAuthorized) {
    // Skip login, if user authorized
    next({ path: redirectPath });
  } else {
    if (to.name === 'verify' && userVerified) {
      // Redirect from verify, if user verified
      next({ path: redirectPath });
      window.$notify.info('Account already verified. Page access denied.')
    } else {
      next();
    }
  }

  /*

  const { user } = data;
  const { student } = user;
  let nextRouteName;

  // will add backend flag for replacing this code
  if (user.isVerified && student) {
    this.user = {
      email: user.email,
      ...student
    };

    if (student.students_details) {
      nextRouteName = 'profile';
    } else {
      nextRouteName = 'professional-details';
    }
  } else {
    this.user = { email: user.email };

    if (user.isVerified) {
      nextRouteName = 'basic-info';
    } else {
      nextRouteName = 'verify';
    }
  }

  this.$router.push({ name: nextRouteName });

  */
};
