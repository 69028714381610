/* eslint-disable */

export default {
  install(Vue, options) {
    Vue.prototype.$notify = {
      success(message) {
        Vue.prototype.$notification.success({
          message: 'Success!',
          description: message,
          class: 'custom-notifier',
          duration: 5
        });
      },
      info(description, message = 'Information!') {
        Vue.prototype.$notification.info({
          message,
          description,
          class: 'custom-notifier',
          duration: 5
        });
      },
      error(message) {
        Vue.prototype.$notification.error({
          message: 'Error!',
          description: message,
          class: 'custom-notifier',
          duration: 5
        });
      },
      parseFeathersErrors(error) {
        if (error?.errors?.length) {
          return error.errors.map((e) => {
            this.error(e.message);
            return e.message;
          });
        } else if (error?.message) {
          this.error(error.message);
          return [ error.message ];
        } else {
          console.warn('parseFeathersErrors:', error);
          return [];
        }
      }
    };

    // for global using
    window.$notify = Vue.prototype.$notify;
  }
}
