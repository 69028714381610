import feathersClient, {
  makeServicePlugin,
  BaseModel
} from '@/plugins/feathersClient';

class Interviews extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'Interviews';

  // Define default properties here
  static instanceDefaults() {
    return {
      id: '',
      status: '',
      userId: '',
      timeslotId: ''
    }
  }
}
const servicePath = 'interviews';
const servicePlugin = makeServicePlugin({
  Model: Interviews,
  service: feathersClient.service(servicePath),
  servicePath
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
});

export default servicePlugin;
