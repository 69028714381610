/* eslint-disable import/first,import/newline-after-import,import/order,arrow-parens */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './plugins/i18n'
import setupFilters from './filters'

// Facebook API
import VueFacebookPixel from 'vue-facebook-pixel'
Vue.use(VueFacebookPixel)

// main.scss
import '@/assets/styles/main.scss'

// Ant Design Vue
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
Vue.use(Antd)

import notify from '@/plugins/notify';
Vue.use(notify);

Vue.config.productionTip = false

setupFilters(Vue);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

// scroll to input element on mobile keyboard show
const onResize = () => {
  if (!document.activeElement) return;
  if (document.activeElement.tagName !== 'INPUT') return;

  let watchFor = document.activeElement;
  let pos = 'center';

  if (watchFor.dataset.scrollanchorstart) {
    watchFor = document.getElementById(watchFor.dataset.scrollanchorstart);
    pos = 'start';
  } else if (watchFor.dataset.scrollanchorcenter) {
    watchFor = document.getElementById(watchFor.dataset.scrollanchorcenter);
    pos = 'center';
  }

  const rect = watchFor.getBoundingClientRect();
  const ds = rect.y - document.documentElement.clientHeight;
  if (ds > 0 || pos) {
    watchFor.scrollIntoView({ block: pos, behavior: 'smooth' });
  }
};
window.addEventListener('resize', onResize);
