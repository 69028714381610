const {
  NODE_ENV,
  VUE_APP_PROD_URL,
  VUE_APP_DEV_URL,
  VUE_APP_CHECKOUT_PROD_URL,
  VUE_APP_CHECKOUT_DEV_URL
} = process.env;

const isProduction = NODE_ENV === 'production';

export default {
  backendUrl: isProduction ? VUE_APP_PROD_URL : VUE_APP_DEV_URL,
  checkoutUrl: isProduction ? VUE_APP_CHECKOUT_PROD_URL : VUE_APP_CHECKOUT_DEV_URL
};
