import Vue from 'vue';
import Vuex from 'vuex';
// eslint-disable-next-line import/no-cycle
import { FeathersVuex, makeAuthPlugin } from '@/plugins/feathersClient';
// import authManagement from './modules/authManagement';

Vue.use(Vuex);
Vue.use(FeathersVuex);

const requireModule = require.context(
  // The path where the service modules live
  './services',
  // Whether to look in subfolders
  false,
  // Only include .js files (prevents duplicate imports`)
  /.js$/
);
const servicePlugins = requireModule
  .keys()
  .map((modulePath) => requireModule(modulePath).default);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  plugins: [
    ...servicePlugins,
    makeAuthPlugin({ userService: 'users' }),
  ]
});
